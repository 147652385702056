import {
  Box,
  Button,
  ButtonGroup,
  Container,
  Divider,
  IconButton,
  SimpleGrid,
  Stack,
  Text,
} from "@chakra-ui/react";
import * as React from "react";
import { HiOutlineMail } from "react-icons/hi";
import Image from "next/image";
import { links } from "./_data";
import { useTranslations } from "next-intl";
import Link from "next/link";

export const Footer = () => {
  const t = useTranslations("Footer");

  return (
    <Box bg="bg-surface">
      <Container as="footer" role="contentinfo">
        <Stack
          justify="space-between"
          align="start"
          direction={{ base: "column", lg: "row" }}
          py={{ base: "12", md: "16" }}
          spacing="8"
        >
          <Stack spacing={{ base: "6", md: "8" }} align="start">
            <Box width="190px">
              <Image
                layout="responsive"
                alt="logo"
                src="/logo_w.png"
                width={1094}
                height={141}
              />
            </Box>

            <Text color="muted">{t("description")}</Text>
          </Stack>
          <SimpleGrid
            columns={{ base: 2, md: 4 }}
            gap="8"
            width={{ base: "full", lg: "auto" }}
          >
            {links.map((group, idx) => (
              <Stack key={idx} spacing="4" minW={{ lg: "40" }}>
                <Text fontSize="sm" fontWeight="semibold" color="sbtle">
                  {t(group.title as any)}
                </Text>
                <Stack spacing="3" shouldWrapChildren>
                  {group.links.map((link, idx) => (
                    <Link key={idx} href={link.href}>
                      {t(link.label as any)}
                    </Link>
                  ))}
                </Stack>
              </Stack>
            ))}
          </SimpleGrid>
        </Stack>
        <Divider />
        <Stack
          pt="8"
          pb="12"
          justify="space-between"
          direction={{ base: "column-reverse", md: "row" }}
          align="center"
        >
          <Text fontSize="sm" color="subtle">
            {t("cc", { date: new Date().getFullYear() })}
          </Text>
          <ButtonGroup variant="ghost">
            <IconButton
              as="a"
              href="mailto:support@silkscan.xyz"
              aria-label="Email"
              icon={<HiOutlineMail fontSize="1.25rem" />}
            />
          </ButtonGroup>
        </Stack>
      </Container>
    </Box>
  );
};
