/* artwork events */
export const SUBMIT_ARTWORK_EVENT = "submit artwork";
export const UPDATE_ARTWORK_EVENT = "update artwork";
export const DELETE_ARTWORK_EVENT = "delete artwork";

export const DOWNLOAD_ARTWORK_LABEL_EVENT = {
  label: "download labels",
  event: {
    numLabels: "artworks selected",
    dimension: "dimension",
    artworkIds: "artwork ids",
  },
};

export const DOWNLOAD_ARTWORK_LIST_EVENT = {
  label: "download lists",
  event: {
    numLabels: "artworks selected",
    dimension: "dimension",
    artworkIds: "artwork ids",
  },
};
/*artist events*/
export const QUICK_PREVIEW_ARTWORK_EVENT = {
  label: "quick preview artwork",
  event: {
    artworkId: "artwork id",
  },
};
export const SUBMIT_ARTIST_EVENT = "submit artist";
export const UPDATE_ARTIST_EVENT = "update artist";
export const DELETE_ARTIST_EVENT = "delete artist";

/*configure events*/
export const SET_LABEL_LAYOUT_EVENT = {
  label: "set label layout",
  eventLabel: { dimensions: "dimensions" },
};

export const SUBMIT_SIGN_UP_EVENT = "submit sign up";
export const SUBMIT_ONBOARD_EVENT = "submit onboard";
export const ARTWORK_FILTER_ARTIST = "submit artwork filter: artist";

export const SUBMIT_NAV_SIGNUP_EVENT = "submit navbar sign up";
export const SUBMIT_CTA_SIGNUP_EVENT = "submit call to action sign up";
export const SUBMIT_CTA_FINAL_SIGNUP_EVENT =
  "submit below fold call to action sign up";
export const SUPPORT_WHAT_EVENT = 'click "What does Silkscan do?"';
export const SUPPORT_PREVIEW_EVENT = 'click "What is a Preview?"';
export const SUPPORT_COST_EVENT = 'click "How much does it cost?"';
export const SUPPORT_TRIAL_EVENT =
  'click "What happens when the free trial runs out?"';

export const UPLOAD_LOGO_EVENT = "upload logo";
export const IMPORT_FROM_FILE_EVENT = "import from file";
export const CREATE_INVOICE_EVENT = "create invoice";
export const FORGOT_PASSWORD_EVENT = "subit forgot password";
export const RESET_PASSWORD_EVENT = "submit reset password";

export const CHANGE_LOCALE_EVENT = {
  label: "change locale",
  event: { locale: "locale" },
};
