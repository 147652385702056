import {
  Box,
  Container,
  Flex,
  HStack,
  ButtonGroup,
  Button,
} from "@chakra-ui/react";
import Link from "next/link";
import Image from "next/image";
import { track } from "services/analytics";
import { SUBMIT_NAV_SIGNUP_EVENT } from "services/analytics/events";

export const DesktopSiteNav = ({
  t,
  isAuthenticated,
  locale,
  isLoading,
  router,
  bg,
  ...styleProps
}: any) => {
  return (
    <Box as="section">
      <Box as="nav" bg={bg} {...styleProps}>
        <Container py={{ base: "3", lg: "4" }}>
          <Flex justify="space-between">
            <HStack spacing="4">
              <Link href="/" locale={locale}>
                <Box as="button" width="190px">
                  <Image
                    priority
                    alt="logo"
                    src="/logo_w.png"
                    width={1094}
                    height={141}
                  />
                </Box>
              </Link>
              <ButtonGroup
                variant="ghost"
                spacing="1"
                display={["none", "initial"]}
              >
                <Link href="/">
                  <Button color="muted">{t("homeBtn")}</Button>
                </Link>
                <Link href="/support">
                  <Button color="muted">{t("supportBtn")}</Button>
                </Link>
                <Link href="/pricing">
                  <Button color="muted">{t("pricingBtn")}</Button>
                </Link>
              </ButtonGroup>
            </HStack>
            <HStack spacing="4">
              {!isLoading &&
                (isAuthenticated ? (
                  <Link href="/admin">
                    <Button colorScheme="brand" fontWeight="bold">
                      {t("dashboard")}
                    </Button>
                  </Link>
                ) : (
                  <ButtonGroup spacing="1">
                    <Link href="/login">
                      <Button color="muted" variant="ghost">
                        {t("loginBtn")}
                      </Button>
                    </Link>
                    <Link href="/sign-up">
                      <Button
                        onClick={() => track(SUBMIT_NAV_SIGNUP_EVENT)}
                        colorScheme="brand"
                      >
                        {t("signUpBtn")}
                      </Button>
                    </Link>
                  </ButtonGroup>
                ))}
            </HStack>
          </Flex>
        </Container>
      </Box>
    </Box>
  );
};
