import { Box } from "@chakra-ui/react";
import * as React from "react";
import { useSession } from "next-auth/react";
import { SiteNavContainer } from "./Container";

export interface SiteNavProps {
  [key: string]: any;
  isAuthenticated?: boolean;
}

export const SiteNav = ({ isAuthenticated, ...styleProps }: any) => {
  const { data: session, status } = useSession();
  return (
    <Box minH="74px" width="100%">
      <SiteNavContainer
        isAuthenticating={status === "loading"}
        isAuthenticated={!!session?.jwt && status === "authenticated"}
        {...styleProps}
      />
    </Box>
  );
};
