import { useColorModeValue, useMediaQuery } from "@chakra-ui/react";
import * as React from "react";
import { useRouter } from "next/router";
import { useTranslations } from "next-intl";
import { MobileSiteNav } from "components/SiteNav/MobileNav";
import { DesktopSiteNav } from "components/SiteNav/DesktopNav";

export interface SiteNavProps {
  [key: string]: any;
  isAuthenticating?: boolean;
  isAuthenticated?: boolean;
}
export const SiteNavContainer = ({
  isAuthenticating,
  isAuthenticated,
  ...styleProps
}: any) => {
  const t = useTranslations("SiteNav");
  const bg = useColorModeValue("gray.50", "gray.800");
  const [isMobile] = useMediaQuery("(max-width: 768px)");
  const router = useRouter();
  const { locale } = router;
  const Nav = isMobile ? MobileSiteNav : DesktopSiteNav;
  return (
    <Nav
      t={t}
      bg={bg}
      router={router}
      locale={locale}
      isLoading={isAuthenticating}
      isAuthenticated={isAuthenticated}
      {...styleProps}
    />
  );
};
