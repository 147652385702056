import {
  Text,
  Box,
  Container,
  Flex,
  HStack,
  Menu,
  MenuButton,
  IconButton,
  MenuList,
  MenuGroup,
  MenuItem,
} from "@chakra-ui/react";
import Link from "next/link";
import { FiMenu } from "react-icons/fi";
import Image from "next/image";
import { track } from "services/analytics";
import { SUBMIT_NAV_SIGNUP_EVENT } from "services/analytics/events";

export const MobileSiteNav = ({
  t,
  isAuthenticated,
  isLoading,
  locale,
  router,
  bg,
  session,
  ...styleProps
}: any) => {
  return (
    <Box as="section">
      <Box as="nav" bg={bg} {...styleProps}>
        <Container py={{ base: "3", lg: "4" }}>
          <Flex justify="space-between">
            <HStack spacing="4">
              <Link href="/" locale={locale}>
                <Box as="button" width="190px">
                  <Image
                    priority
                    alt="logo"
                    src="/logo_w.png"
                    width={1094}
                    height={141}
                  />
                </Box>
              </Link>
            </HStack>
            <Menu>
              <MenuButton
                as={IconButton}
                aria-label="Open menu"
                icon={<FiMenu fontSize="1.25rem" />}
                variant="outline"
              />
              <MenuList>
                <MenuGroup>
                  {!isLoading &&
                    (isAuthenticated ? (
                      <MenuItem
                        onClick={() =>
                          router.push("/admin", "/admin", { locale })
                        }
                      >
                        <Text> {t("dashboard")}</Text>
                      </MenuItem>
                    ) : (
                      <>
                        <MenuItem
                          onClick={() =>
                            router.push("/login", "/login", { locale })
                          }
                        >
                          <Text>{t("loginBtn")}</Text>
                        </MenuItem>
                        <MenuItem
                          onClick={() => {
                            track(SUBMIT_NAV_SIGNUP_EVENT);
                            router.push("/sign-up", "/sign-up", { locale });
                          }}
                        >
                          <Text>{t("signUpBtn")}</Text>
                        </MenuItem>
                      </>
                    ))}
                  <MenuItem
                    onClick={() =>
                      router.push("/support", "/support", { locale })
                    }
                  >
                    <Text> {t("supportBtn")}</Text>
                  </MenuItem>
                  <MenuItem
                    onClick={() =>
                      router.push("/pricing", "/pricing", { locale })
                    }
                  >
                    <Text> {t("pricingBtn")}</Text>
                  </MenuItem>
                </MenuGroup>
              </MenuList>
            </Menu>
          </Flex>
        </Container>
      </Box>
    </Box>
  );
};
