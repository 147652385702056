export const links = [
  {
    title: "productHeader",
    links: [
      { label: "howItWorks", href: "/#learn-more" },
      { label: "features", href: "/#features" },
      { label: "pricing", href: "/pricing" },
    ],
  },
  {
    title: "legal",
    links: [
      { label: "terms", href: "/terms" },
      { label: "privacy", href: "privacy" },
    ],
  },
];
